<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    style="padding-top: 0;padding-bottom: 0"
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
        style="padding-top: 0;padding-bottom: 0"
      >
        <v-card-title
          style="padding: 0"
        >
          <v-flex
            xs12
            md9
            style="padding: 0"
          >
            <v-btn
              class="mx-3  font-weight-medium"
              color="green"
              @click.prevent="showFileDialog">
              <v-icon>mdi-cloud-upload
              </v-icon>
              <span class="mx-2 font-weight-medium">上传素材</span>
            </v-btn>

          </v-flex>
          <v-flex
            xs12
            md3
            style="padding: 0"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-flex>
        </v-card-title>
        <material-card
          color="green"
          style="margin-top: 30px"
        >
          <div
            slot="header"
            class="d-flex">
            <span>
              <div class="title mb-2">
                素材信息表
              </div>
            </span>
            <span
              style="text-align:right;margin-top: -10px">
              <div>
                <span style="margin-right:20px;">
                  <v-btn
                    small
                    round
                    class="mx-0 font-weight-medium"
                    color="transparent"
                    style="margin-right:20px"
                    @click="fileList"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </span>
              </div>
            </span>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :pagination.sync="pagination"
            :expand="expand"
            class="elevation-3 "
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success  text--darken-3 text-xs-center"
                v-text="header.text"
              />
            </template>
            <template
              v-slot:items="rowsprops"
            >
              <tr @click="getImg(rowsprops)">
                <td/>
                <td>{{ rowsprops.item.index }}</td>
                <td>{{ rowsprops.item.srcname }}</td>
                <td>{{ rowsprops.item.filesize }}</td>
                <td>{{ rowsprops.item.uploadtime }}</td>
                <td style="padding-top: 0;padding-bottom: 0">
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="red"
                    icon
                    @click.stop="showFileEditDialog(rowsprops)"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </td>
                <td style="padding-top: 0;padding-bottom: 0">
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="red"
                    icon
                    @click.stop="showFileDeleteDialog(rowsprops.item.srcname,rowsprops.item.id)"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template
              v-slot:expand="rowsprops">
              <v-card>
                <v-container
                  fill-height
                  fluid
                  pa-2
                >
                  <v-layout fill-height>
                    <v-flex
                      xs12
                      md2>
                      <v-card-text
                        width="100"
                        style="float: left"
                      >
                        <v-img
                          :src="rowsprops.item.img"
                          height="100"
                          width="100"
                          style="background: #eeeeee"
                          @click="$previewImageLite.preview(rowsprops.item.img)"
                        />
                      </v-card-text>
                    </v-flex>
                    <v-flex
                      xs12
                      md10
                    >
                      <v-card-text
                        width="100"
                        style="float: left"
                      >
                        <span>文件说明：<br>
                          {{ rowsprops.item.filedesc }}</span>
                      </v-card-text>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </template>
          </v-data-table>
          <v-layout>

            <v-flex
              xs12
              md1

            >
              <v-select
                v-model="pagination.rowsPerPage"
                :items="selectedItems"
                label="每页"
                @change="selectChanged"
              />
            </v-flex>

            <v-flex
              xs12
              md11
              class="text-xs-right"
            >
              <div class=" pt-2 ">
                <v-pagination
                  v-model="pagination.page"
                  :length="pages"
                  sort-by="文件大小"
                />
              </div>
            </v-flex>
          </v-layout>
        </material-card>
      </v-flex>
    </v-layout>

    <!--弹出上传素材填写框 S-->
    <v-dialog
      v-model="showhide.FileDialog"
      max-width="600"
      persistent
      @keydown.esc="showhide.FileDialog = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title mb-2">上传素材</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeFileDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>

            </div>
            <v-flex
              xs12
              md12
            >
              <v-flex
                xs12
                md12
              >
                <v-text-field
                  ref="uploadfile"
                  :value="fileDialog.filename"
                  label="文件"
                  prepend-inner-icon="mdi-link-variant"
                  readonly
                  persistent-hint
                  hint="仅限.mp4文件，且大小不超过100M"
                  style="margin-top:20px"
                  @click.stop="openFile"
                  @keyup.enter="uploadFile"
                />
                <input
                  id="inputHidden"
                  ref="inputHidden"
                  type="file"
                  accept=".mp4"
                  multiple
                  @change="inputChanged">
              </v-flex>
              <v-text-field
                v-model="fileDialog.filedesc"
                label="文件说明"
                required
                @keyup.enter="uploadFile"
              />
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="uploadFile"
            >
              确认上传
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出上传素材填写框 E-->

    <!-- 弹出文件上传列表 S -->
    <div
      id="app"
    >
      <v-container
        fluid
      >
        <transition
        >
          <div
            v-if="uploadList.flag"
          >
            <div
              class="d-flex"/>
            <v-card
              class="v-card--material__header"
              color="green">
              <v-card-title
                class="title font-weight-light mb-2"
                color="#fff">
                <v-flex
                  xs12
                  md10
                >
                  文件上传列表
                </v-flex>
                <v-flex
                  xs12
                  md1
                  text-xs-right
                >
                  <span :class="showhide.MinFlag">
                    <v-icon
                      size="24"
                      color="#fff"
                      @click="minUploadList">
                      mdi-chevron-down-circle-outline
                    </v-icon>
                  </span>
                  <span :class="showhide.MaxFlag">
                    <v-icon
                      size="24"
                      color="#fff"
                      @click="maxUploadList">
                      mdi-chevron-up-circle-outline
                    </v-icon>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  md1
                  text-xs-right
                >
                  <span >
                    <v-icon
                      size="24"
                      color="#fff"
                      @click="closeUploadList">
                      mdi-close-circle-outline
                    </v-icon>
                  </span>
                </v-flex>
              </v-card-title>
              <div :class="showhide.UploadList">
                <v-data-table
                  :headers="uploadheaders"
                  :items="uploaditems"
                  hide-actions
                >
                  <template
                    slot="items"
                    slot-scope="{ item }"
                    persistent
                  >
                    <td class="font-weight-regular">{{ item.filename }}</td>
                    <td class="font-weight-light">{{ item.size }}</td>
                    <td class="font-weight-light">{{ item.uploaddate }}</td>
                    <td class="font-weight-light">
                      <v-progress-linear
                        :value="item.progress"
                        class="d-flex"
                        color="green"
                        height="25"
                        reactive
                      >
                        <div class="text-xs-center">
                          <strong >{{ item.progress }}</strong>
                        </div>
                      </v-progress-linear>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </div>
        </transition>
      </v-container>
    </div>
    <!--    弹出文件上传列表 E-->

    <!--弹出的文件编辑框 S-->
    <v-dialog
      v-model="showhide.EditDialog"
      max-width="600"
      persistent
      @keydown.esc="showhide.EditDialog = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title mb-2">编辑</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeFileEditDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-model="editDialog.filename"
                label="文件名"
                required
                readonly
              />
            </v-flex>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-focus
                id="delinput"
                ref="delinput"
                v-model="editDialog.filedesc"
                label="文件说明"
                required
                @keyup.enter="updateFile"
              />
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="updateFile"
            >
              OK
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出的文件编辑框 E-->

    <!--弹出的删除确认框 S-->
    <v-dialog
      v-model="showhide.DeleteDialog"
      max-width="600"
      persistent
      @keydown.esc="showhide.DeleteDialog = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title mb-2">文件名验证</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeFileDeleteDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>
              <div>
                <p class="category font-weight-medium">输入文件名后确认删除：<span class="title text-warning font-weight-bold">{{ deleteDialog.confirmfilename }}</span></p>
              </div>
            </div>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-focus
                ref="delinput"
                v-model="deleteDialog.filename"
                label="文件名"
                required
                @keyup.enter="deleteFile"
              />
              <div style="display: none">
                <v-text-field
                  v-model="deleteDialog.confirmfilename"
                  label="文件名"
                />
              </div>
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="deleteFile"
            >
              确认删除
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出的删除确认框 E-->

  </v-container>
</template>

<script>
export default {
  directives: {
    focus (el) {
      let dialogusername = document.getElementById('dialogusername')
      if (dialogusername !== undefined && dialogusername != null) {
        dialogusername.focus()
      }
    }
  },
  data: () => ({
    search: '',
    showhide: {
      FileDialog: false,
      UploadList: 'd-flex',
      MinFlag: 'd-flex',
      MaxFlag: 'd-none',
      DeleteDialog: false,
      EditDialog: false,
      imgdialog: false
    },
    fileDialog: {
      filename: '',
      filedesc: '',
      file: []
    },
    editDialog: {
      filename: '',
      filedesc: '',
      id: ''
    },
    deleteDialog: {
      filename: '',
      confirmfilename: '',
      fileId: ''
    },
    imgDialog: {
      img: ''
    },
    uploadList: {
      flag: false,
      count: 0
    },
    pagination: { page: 3, rowsPerPage: 5, totalItems: '', sortBy: '' },
    expand: true,
    selectedItems: ['5', '10', '20', '50', '100'],
    headers: [
      { sortable: false, text: '', value: '', width: '3%' },
      { sortable: false, text: '#', value: 'index', width: '10%' },
      { sortable: true, text: '文件名', value: 'srcname', width: '35%' },
      { sortable: true, text: '文件大小', value: 'filesize', width: '15%' },
      { sortable: true, text: '上传日期', value: 'uploadtime', width: '20%' },
      { sortable: false, text: '编辑', value: '', width: '10%' },
      { sortable: false, text: '删除', value: '', width: '10%' }
    ],
    items: [
    ],
    uploadheaders: [
      { sortable: false, text: '文件名称', value: 'uploadfilename', width: '40%' },
      { sortable: false, text: '大小', value: 'uploadfilesize', width: '10%' },
      { sortable: false, text: '上传日期', value: 'uploaddate', width: '20%' },
      { sortable: false, text: '状态', value: 'uploadstatus', width: '30%' }
    ],
    uploaditems: []
  }),
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
      ) return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    }
  },
  created () {
    this.fileList()
  },
  methods: {
    selectChanged () {
      let maxPage = Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      if (this.pagination.page > maxPage) {
        this.pagination.page = maxPage
      }
      this.$localStorage.set(this.$session.get('signIn').username + '_rowsPerPage', this.pagination.rowsPerPage)
    },
    fileList () {
      this.items = []
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/file/fileList?pageid=1&size=2000', this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.fileList.length; i++) {
              let obj = {
                'id': res.detail.fileList[i].id,
                'srcname': res.detail.fileList[i].srcName,
                'filesize': this.$formatFileSize(res.detail.fileList[i].fileSize),
                'uploadtime': this.$getDateString(res.detail.fileList[i].timeStamp),
                'filedesc': res.detail.fileList[i].fileDesc,
                'index': i + 1,
                'img': ''
              }
              this.items.push(obj)
            }
            this.pagination.totalItems = res.detail.fileList.length
            let rowsPerPage = this.$localStorage.get(this.$session.get('signIn').username + '_rowsPerPage')
            if (rowsPerPage === '' || rowsPerPage === null) {
              this.pagination.rowsPerPage = '5'
            } else {
              this.pagination.rowsPerPage = rowsPerPage
            }
            console.log(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    getImg (rowsprops) {
      if (rowsprops.expanded === true) {
        rowsprops.expanded = false
        return
      }
      rowsprops.expanded = !rowsprops.expanded
      if (!this.$isEmpty(rowsprops.item.img)) {
        return
      }
      let img = ''
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/file/getUrl?fileId=' + rowsprops.item.id, this.$authHeaders())
        .then(result => {
          let imgRes = result.data
          if (imgRes.code === 0) {
            img = imgRes.detail.thumbnail
          }
          rowsprops.item.img = img
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    showFileDialog () {
      this.showhide.FileDialog = true
      this.fileDialog.filename = ''
      this.fileDialog.filedesc = ''
    },
    closeFileDialog () {
      this.showhide.FileDialog = false
    },
    openFile () {
      this.$refs.inputHidden.value = ''
      this.fileDialog.filename = ''
      let uploadbtn = this.$refs.inputHidden
      uploadbtn.click()
    },
    inputChanged (event) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i] === undefined || event.target.files[i] == null) {
          this.$refs.inputHidden.value = ''
          this.fileDialog.filename = ''
        } else {
          this.fileDialog.file.push(event.target.files[i]) // 获取文件
          let name = event.target.files[i].name
          let size = event.target.files[i].size
          let filesize = parseInt(this.$formatFileSize(size))
          if (this.$formatFileSize(size).includes('MB')) {
            if (filesize > 100) {
              this.$snackbar.info('请选择小于100MB的文件')
            }
          }
          if (this.$formatFileSize(size).includes('GB')) {
            if (filesize > 0.1) {
              this.$snackbar.info('请选择小于100MB的文件')
            }
          }
          if (name.length > 50) {
            this.$snackbar.info('请输入少于50个字符')
          }
          if (i === 0) {
            this.fileDialog.filename = name + ' (' + this.$formatFileSize(size) + ')'
          } else {
            this.fileDialog.filename = this.fileDialog.filename + ',' + name + ' (' + this.$formatFileSize(size) + ')'
          }
        }
      }
    },
    uploadFile () {
      let files = this.$refs.inputHidden.files
      // console.log(files)
      if (files.length > 6) {
        this.$snackbar.info('每次最多可上传6个文件')
        return
      }
      if (this.uploaditems.length + files.length > 6) {
        this.$snackbar.info('每次最多可上传6个文件')
        return
      }
      if (files.length <= 0) return
      let size = ''
      let filename = ''
      for (let i = 0; i < files.length; i++) { // 多文件
        size = files[i].size
        filename = files[i].name
        if (this.$formatFileSize(size).includes('MB')) {
          let filesize = parseInt(this.$formatFileSize(size))
          if (filesize > 100) {
            this.$snackbar.info('请选择小于100MB的文件')
            return
          }
        } else if (this.$formatFileSize(size).includes('GB')) {
          let filesize = parseInt(this.$formatFileSize(size))
          if (filesize > 0.1) {
            this.$snackbar.info('请选择小于100MB的文件')
            return
          }
        }
        if (filename.length > 50) {
          this.$snackbar.info('请输入少于50个字符')
          return
        }
        let obj = {
          'count': this.uploadList.count,
          'filename': filename,
          'size': this.$formatFileSize(size),
          'progress': 0,
          'uploaddate': this.$getDateTimeString()
        }
        this.uploaditems.push(obj)
      }
      this.uploadList.flag = 'true'
      this.$authHeaders()
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let index = this.uploadList.count
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': this.$store.state.app.signIn.token
          },
          onUploadProgress: progressEvent => {
            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
            this.uploaditems[index].progress = complete
          }
        }
        this.uploadList.count = this.uploadList.count + 1
        let formdata = new FormData()
        formdata.append('File', file)
        formdata.append('Description', this.fileDialog.filedesc)
        this.$http
          .post(this.$store.state.app.url + '/mediaSystem/file/uploadFile', formdata, config)
          .then(res => {
            if (res.data.code === 0) {
              // console.log(res)
              for (let i = 0; i < this.uploaditems.length; i++) {
                if (this.uploaditems[i].progress === '100%') {
                  this.uploaditems.splice(i, 1)
                }
              }
              if (this.uploaditems === undefined || this.uploaditems.length <= 0) {
                this.uploadList.flag = false
                this.uploadList.count = 0
              }
              this.fileList()
            } else {
              this.$snackbar.info(res.data.message)
            }
          }, (error) => {
            this.$httpCatch(error)
          })
      }
      this.closeFileDialog()
    },
    minUploadList () {
      this.showhide.UploadList = 'd-none'
      this.showhide.MinFlag = 'd-none'
      this.showhide.MaxFlag = 'd-flex'
    },
    maxUploadList () {
      this.showhide.UploadList = 'd-flex'
      this.showhide.MinFlag = 'd-flex'
      this.showhide.MaxFlag = 'd-none'
    },
    closeUploadList () {
      this.uploadList.flag = false
      this.uploaditems = []
      this.uploadList.count = 0
      this.fileDialog.file = []
    },
    showFileEditDialog (rowsprops) {
      this.showhide.EditDialog = true
      this.editDialog.id = rowsprops.item.id
      this.editDialog.filename = rowsprops.item.srcname
      this.editDialog.filedesc = rowsprops.item.filedesc
    },
    closeFileEditDialog () {
      this.showhide.EditDialog = false
    },
    updateFile () {
      let formdata = new FormData()
      formdata.append('fileId', this.editDialog.id)
      formdata.append('Description', this.editDialog.filedesc)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/file/modifyDescribe', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.fileList()
            this.closeFileEditDialog()
            this.$snackbar.success(res.message)
          } else {
            this.$snackbar.error(res.message)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    },
    showFileDeleteDialog (filename, id) {
      this.showhide.DeleteDialog = true
      this.deleteDialog.filename = ''
      this.deleteDialog.confirmfilename = filename
      this.deleteDialog.fileId = id
      // this.deleteDialog.filename = filename
      // this.deleteDialog.confirmfilename = filename
      // this.deleteDialog.fileId = id
      // this.deleteFile()
    },
    closeFileDeleteDialog () {
      this.showhide.DeleteDialog = false
    },
    deleteFile () {
      if (this.deleteDialog.confirmfilename !== this.deleteDialog.filename) {
        this.$snackbar.error('输入不正确，请重新输入')
        return
      }
      let formdata = new FormData()
      // console.log(this.deleteDialog.fileId)
      formdata.append('fileId', this.deleteDialog.fileId)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/file/deleteFile', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].srcname === this.deleteDialog.filename) {
                this.items.splice(i, 1)
                this.closeFileDeleteDialog()
              }
            }
            this.fileList()
            this.$snackbar.success(res.message)
          } else {
            this.$snackbar.error(res.message)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    }
  }
}
</script>

<style scoped>
#inputHidden {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  #app{
     opacity: 1;
     position: fixed;
     bottom: 0;
     right: 0;
     width: 50%;

    /*解决出现横向滚动条问题*/
     overflow-x: hidden;
     overflow-y: hidden;
  }
  .v-enter {
    opacity: 0;
    transform: translateY(100%);
    position: absolute;
  }
  .v-leave-to {
    transform: translateY(100%);
  }
  .v-enter-active,.v-leave-active {
    transition: all 0.4s ease
  }
</style>
